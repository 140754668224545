import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import parse from "html-react-parser"
// import cropped_soccer from "../../content/assets/logo2-300x166.jpg"
// import { GatsbyImage } from "gatsby-plugin-image"
// import { graphql, useStaticQuery } from 'gatsby'



const CurrentNews = () => {
  const query = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: [date], order: DESC}, limit: 2, skip: 1) {
      edges {
        node {
          id
          title
          featuredImageId
          link
          uri
          date
          dateGmt
          author{
            node {
              id
              name
              nicename
              firstName
              lastName
            }
          }
          categories{
            nodes{
              name
            }
          }
          featuredImage {
            node {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`)

  const arr = query.allWpPost.edges;

  return(
    // inner ruturn start---------------
      <div className="my-4">
          <div className="flex flex-wrap flex-col md:flex-row">
          {
            arr.map(post => {
            // const featuredImage = post?.node?.localFile?.childImageSharp?.gatsbyImageData;
            const featuredImage = post?.node?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;
            const img_src = featuredImage?.images?.fallback?.src;
            const img_srcset = featuredImage?.images?.fallback?.srcSet;
            const img_sizes = featuredImage?.images?.sources?.sizes;
            return (
                // disply code starts------------------------------                       
                      <div className="basis-1/2 my-2">
                      {/* <pre className="m-1">{JSON.stringify(post.node.link, null, 4)}</pre> */}
                      {/* <pre className="m-1">{JSON.stringify(featuredImage, null, 4)}</pre> */}
                    
                        <Link to={post.node.link} className="flex flex-col mx-1 items-center bg-white rounded-lg border shadow-md md:max-w-xl hover:bg-gray-100     dark:bg-gray-800 dark:text-white  dark:border-secondary dark:hover:bg-gray-700">
                        {featuredImage ? (
                           <img srcSet={img_srcset} sizes={img_sizes} src={img_src} className="object-cover w-full h-40 rounded-lg md:w-full md:rounded-none md:rounded-lg" alt="" />
                        ) : (
                          <img srcSet={img_srcset} sizes={img_sizes} src={img_src} className="object-cover w-full h-auto rounded-t-lg md:h-auto md:w-full md:rounded-none md:rounded-l-lg" alt="" />
                        )}
                          <div className="flex flex-col justify-between p-4 leading-normal ">
                          <h5 className="mb-2 text-1xl text-blue-650 font-semibold dark:text-slate-300">{post.node.title}</h5>
                              <p className="mb-3 font-normal text-blue-500 dark:text-gray-400 text-xs">
                                <div className="flex opacity-75">
                                  <span>
                                    {new Date(post.node.date).getDate()}&nbsp; 
                                    {new Date(post.node.date).toLocaleString('default', { month: 'long' })}&nbsp; 
                                    {new Date(post.node.date).getFullYear()} 
                                  </span>
                                  {/* &nbsp;
                                  <span className="pr-2"> {post.node.categories.nodes[0].name}</span>
                                  <span>
                                    <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>
                                  </span>
                                  <span className="pr-2">100</span>

                                  <span>
                                    <svg class="h-5 w-5 text-blue-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                  </span>
                                  <span>200</span> */}
                                </div>
                                <div className="flex opacity-75">
                                    <a href="/">{post.node.author.node.name}</a>
                                </div>
                              </p>
                          </div>
                        </Link>
                      </div>
                // disply code ends------------------------------
                    )
                  })
          }
          </div>
      </div>
    // inner return ends ----------------

  )
}


export default function CurrentNewsRender() {
  return (
    <>
      <div className="bg-blue-650 pb-2 my-2 dark:border-gray-700 dark:bg-gray-800">
        <h3 className="block mb-4 text-1xl bg-blue-650 text-white  font-semibold p-2 dark:border-gray-700 dark:bg-gray-800">News</h3>
        <CurrentNews />
      </div>
    </>
  );
}

