import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import parse from "html-react-parser"
// import { graphql, useStaticQuery } from 'gatsby'



const Videos = () => {
  const query = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: [date], order: DESC}, limit: 1, skip: 6) {
      edges {
        node {
          id
          title
          featuredImageId
          link
          uri
          featuredImage {
            node {
              id
              gatsbyImage(fit: COVER, width: 400, quality: 100)
            }
          }
          excerpt
          content
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`)


  const arr = query.allWpPost.edges;

  return(
    // inner ruturn start---------------
      <div className="my-4">
          <div className="flex">
          {
            arr.map(post => {
            // const img_src = post.node?.gatsbyImage?.images?.fallback?.src;
            // const img_srcset = post.node?.gatsbyImage?.images?.fallback?.srcSet;
            // const img_sizes = post.node?.gatsbyImage?.images?.sources?.sizes;
            return (
                // disply code starts------------------------------                       
                      // <div className="aspect-w-16 aspect-h-9">
                      <div className="w-full">
                      {/* <pre className="m-1">{JSON.stringify(post.node, null, 4)}</pre> */}
                        {/* <Link to={post.node.link} className="flex flex-col mx-1 items-center bg-white rounded-lg border shadow-md md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                          <img srcSet={img_srcset} sizes={img_sizes} src={img_src} className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" alt="" />
                          <div className="flex flex-col justify-between p-4 leading-normal">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{post.node.title}</h5>
                              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{post.node.excerpt && parse(post.node.excerpt.substring(0, 35))}</p>
                          </div>
                        </Link> */}
                        <iframe className="w-full" src="https://www.youtube.com/embed/PhwDyyo7GCY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <p className="mb-3 font-normal text-blue-500 dark:text-gray-400 text-xs">
                          <div className="flex opacity-75">
                            <span className="pr-2">Shocking level of match-fixing there</span>
                          </div>
                        </p>
                      </div>
                // disply code ends------------------------------
                    )
                  })
          }
          </div>
      </div>
    // inner return ends ----------------

  )
}

// export default Videos
export default function VideosRender() {
    return (
      <div className="dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
      <Link to="/videolistpage/">
          <h3 className="block my-4 text-1xl bg-blue-650 text-white font-semibold p-2 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">Watch Videos</h3>
      </Link>
      <Videos />
      </div>
    );
  }

